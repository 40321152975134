/* Full Calendar */

#calendar{
  .fc-header-toolbar{
    background: $white;
    padding: 28px 0px 28px 0px;
    margin-bottom: 0;
    .fc-button-group{
      .fc-button{
        box-shadow: none;
        padding: 1px 12px;
        @include border-radius(2px);
        margin-right: 4px;
        font-size: 0.9375rem;
        font-weight: 400;
        line-height: 1.33;
        border: none;
        background: theme-color(primary);
        color: $white;
        text-shadow: none;
        @include transition-duration(0.3s);
        &:first-letter{
          text-transform: uppercase;
        }
        &.fc-state-active{
          background: $white;
          color: theme-color(primary);
        }
        &.fc-prev-button,&.fc-next-button{
          background: $white;
          color: theme-color(primary);
          margin-right: 7px;
          height: 2.0625rem;
          width: 2.25rem;
          border: none;
          span{
            top: -3px;
            right: 9px;
            &:after{
              font: normal normal normal 24px/1 "Material Design Icons";
              display: block;
              line-height: 21px;
            }
          }
        }
        &.fc-prev-button{
          span{
            &:after{
              content: "\F141";
            }
          }
        }
        &.fc-next-button{
          span{
            &:after{
              content: "\F142";
            }
          }
        }
      }
    }
    .fc-today-button{
      box-shadow: none;
      padding: 1px 12px;
      @include border-radius(2px);
      margin-right: 4px;
      font-size: 0.9375rem;
      font-weight: 400;
      line-height: 1.33;
      border: none;
      background: theme-color(primary);
      color: $white;
      text-shadow: none;
      @include transition-duration(0.3s);
      &:first-letter{
        text-transform: uppercase;
      }
    }
    .fc-left,
    .fc-right {
      @media (max-width:767px) {
        float: none;
        .fc-button {
          margin-bottom: 1rem;          
        }
      }
    }
    .fc-center{
      h2{
        font-size: 1.125rem;
        font-weight: 600;
        text-transform: uppercase;
      }
      @media (max-width:767px) {
        display: block;
        h2 {
          width: 100%;
        }
      }
    }
  }
  .fc-view-container{
    .fc-view{
      border-color: $border-color;
      table{
        thead.fc-head{
          border-color: $border-color;
          tr{
            td{
              .fc-widget-header{
                border-top: none;
                table{
                  thead{
                    tr{
                      th.fc-day-header{
                        text-transform: uppercase;
                        padding: 0.4375rem 0 0.4375rem 0;
                        border-color: $border-color;
                        font-size: $default-font-size;
                      }
                    }
                  }
                }
              }
            }
          }
        }
        tbody.fc-body{
          tr{
            td.fc-widget-content{
              border-color: $border-color;
              .fc-day-grid-container{
                .fc-day-grid{
                  .fc-row{
                    border-color: $border-color;
                    .fc-bg{
                      table{
                        tr{
                          td{
                            border-color: $border-color;
                          }
                        }
                      }
                    }
                    .fc-content-skeleton{
                      table{
                        thead{
                          tr{
                            td.fc-day-top{
                              padding: 14px;
                            }
                          }
                        }
                        tbody{
                          tr{
                            td{
                              &.fc-event-container{
                                .fc-h-event{
                                  background: theme-color(success);
                                  border: none;
                                  padding: 6px 12px 6px 22px;
                                  font-size: 12px;
                                  font-weight: 500;
                                  color: $white;
                                  @include border-radius(0px);
                                }
                              }
                              &.fc-more-cell{
                                font-weight: 600;
                                color: theme-color(dark);
                                padding: 0 7px;
                              }
                            }
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}

/* Fonts */

//Roboto
@font-face {
  font-family: 'roboto-light';
  src: url('../fonts/Roboto/Roboto-Light.ttf')  format('truetype'), /* Safari, Android, iOS */
}
@font-face {
  font-family: 'roboto-regular';
  src: url('../fonts/Roboto/Roboto-Regular.ttf')  format('truetype'), /* Safari, Android, iOS */
}
@font-face {
  font-family: 'roboto-medium';
  src: url('../fonts/Roboto/Roboto-Medium.ttf')  format('truetype'), /* Safari, Android, iOS */
}
@font-face {
  font-family: 'roboto-bold';
  src: url('../fonts/Roboto/Roboto-Bold.ttf')  format('truetype'), /* Safari, Android, iOS */
}



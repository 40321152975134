/* Miscellanoeous */

body {
  background: $sidebar-light-bg;
}

body,
html {
  overflow-x: hidden;
}

.content-wrapper {
  @extend .d-flex;
  @extend .flex-column;
  background: $content-bg;
  min-height: 100vh;
  padding: 1.5rem 1.7rem;
  transition: width $action-transition-duration $action-transition-timing-function, margin $action-transition-duration $action-transition-timing-function;
  width: calc(100% - #{$sidebar-width-lg});
  @media (max-width: 991px) {
    margin-left: 0;
    width: 100%;
  }

  &.full-page-wrapper {
    margin-left: 0;
    width: 100%;
  }
}

.container-scroller {
  overflow: hidden;
}

pre {
  background: color(gray-lighter);
  padding: 15px;
  font-size: 14px;
}

code {
  background: color(gray-lightest);
  padding: .2rem .4rem;
}
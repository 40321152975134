/* Bootstrap Tour */

.tour-tour {
  padding: 0;
  border: 0;
  font-family: $type1-regular;
  background: $white;
  .popover-title {
    background: theme-color(primary);
    color: $white;
    font-size: .8125rem;
    font-family: $type1-semibold;
    padding: .75rem;
  }
  &.left {
    .arrow {
      &:after {
        border-left-color: $white;
      }
    }
  }
  &.right {
    .arrow {
      &:after {
        border-right-color: $white;
      }
    }
  }
  &.top {
    .arrow {
      &:after {
        border-top-color: $white;
      }
    }
  }
  &.bottom {
    .arrow {
      &:after {
        border-bottom-color: $white;
      }
    }
  }
  &.fade {
    &:not(.show) {
      opacity: 1;
    }
  }
}
.tour-step-background {
  display: none;
}